
import { defineComponent, PropType } from 'vue';
import { Maneuver } from '@/models';

export default defineComponent({
  props: {
    maneuvers: {
      type: Array as PropType<Array<Maneuver>>
    }
  },
  setup() {
    return {};
  }
});
