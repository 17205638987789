import { Phase } from '../models';

// must fix formatCategory to not use replaceAll becuase NodeJS doesn't support it
//https://stackoverflow.com/questions/65295584/jest-typeerror-replaceall-is-not-a-function
export function formatCategory(categoryName: string): string {
  return categoryName.replace('/_/g', ' ');
}

export function formatPhase(phase: Phase): string {
  return phase.charAt(0).toUpperCase() + phase.slice(1);
}
