
import { computed, defineComponent, PropType, watch } from 'vue';
import { Event, Maneuver } from '@/models';
import SyllabusEventManeuverTable from '@/components/syllabus/SyllabusEventManeuverTableComponent.vue';
import Routes from '@/router/Routes';
import store from '@/store';
import { useRoute, useRouter } from 'vue-router';
import { formatCategory, formatPhase } from '@/services/FormatService';

export default defineComponent({
  components: {
    SyllabusEventManeuverTable
  },
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true
    }
  },
  emits: ['prerequisitClick'],
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    watch(() => route.params.eventName, fetchEvent);

    async function fetchEvent() {
      const courseData = store.getters['courseModule/currentCourse'];
      await store.dispatch('eventModule/fetchSyllabusEvent', {
        name: route.params.eventName,
        syllabusId: courseData.downloadId
      });
    }

    const maneuvers = computed(() => {
      return props.event.maneuvers.filter((maneuver: Maneuver) => {
        return maneuver.mif.value !== 'NONE';
      });
    });

    async function handlePrerequisitClick(eventName: string) {
      router.push({
        name: Routes.SYLLABUS_EVENT_DETAILS,
        params: { eventName }
      });
    }

    return {
      formatCategory,
      formatPhase,
      handlePrerequisitClick,
      maneuvers,
      route
    };
  }
});
